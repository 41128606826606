"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  CARD: 'card',
  BOX: 'box'
};
exports["default"] = _default;